import React from 'react'
import classNames from 'classnames'
import { Link } from 'gatsby'

import { Divider } from 'semantic-ui-react'

const FaqsNavBar = () => (
  <>
    <div className="container-fluid px-2 my-4">
      <h1 className="text-center pt-4">F.A.Q</h1>
      <ul
        className={classNames(
          'nav-bar',
          'd-flex flex-wrap  align-items-center justify-content-center'
        )}
      >
        <li className={classNames('nav-bar-item', 'mb-4')}>
          <Link to="/faq/general" activeClassName="active-nav-bar-item" className="nav-bar-link">
            General Questions
          </Link>
        </li>
        <li className={classNames('nav-bar-item', 'mb-4')}>
          <Link
            to="/faq/pedi-spa-chair"
            activeClassName="active-nav-bar-item"
            className="nav-bar-link"
          >
            Pedi-Spa Chair Questions
          </Link>
        </li>
        <li className={classNames('nav-bar-item', 'mb-4')}>
          <Link to="/faq/shipping" activeClassName="active-nav-bar-item" className="nav-bar-link">
            Shipping Questions
          </Link>
        </li>
      </ul>
      <Divider />
    </div>
  </>
)

export default FaqsNavBar
