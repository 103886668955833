import React from 'react'
import { Accordion, Icon } from 'semantic-ui-react'
import { Link } from 'gatsby'
import Layout from '../../components/Layout'
import SEO from '../../components/SEO'
import FaqsNavBar from '../../components/FaqsNavBar'

const GeneralPage = () => {
  // component state
  const [activeIndex, setActiveIndex] = React.useState(0)

  const toggleAccordion = React.useCallback(
    (e, titleProps) => {
      e.preventDefault()
      const { index } = titleProps
      const newIndex = activeIndex === index ? -1 : index
      setActiveIndex(newIndex)
    },
    [activeIndex]
  )

  return (
    <>
      <Layout>
        <SEO
          title="General FAQ"
          description="Do you have any general questions? You might find your answer from most frequently questions asked by other customers. If not, drop us a line. We will answer your question as soon as possible."
          meta={[
            {
              name: 'keywords',
              content:
                'Nail salon design,Nail salon startup cost,Pedicure spa chair,Contego,Contego spa,Contego spa designs,Contego spa designs inc,Contego spa liner,Contego spa chair review,Contego spa reviews,Contego spa pedicure,Contego spa pedicure chair,Contego pedicure chairs price,Pedicure chair,Pedicure spa,Spa chair,Design ideas for nail salon,Luxury pedicure chairs,Cheap pedicure chairs,Pedicure spa chairs for sale,Cheap spa pedicure chairs,pedicure spa chair package deal,Contego pedicure chairs,Pedicure chairs,Pedicure chairs for sale,Pedicure chairs with massage,Pedicure chairs cheap,Pedicure chairs parts,Pedicure chairs wholesale,Pedicure chair ideas,Pedicure chair for sale,Spa pedicure chairs,Nail salon pedicure chair,Nail Salon Furniture,Nail Salon Equipment,Pedi spa chair,Spa pedicure chairs,Salon Equipment,Pedicure chair parts,Pedicure furniture'
            }
          ]}
        />
        <FaqsNavBar />
        <div className="container py-4 responsive-fh animated fadeInUp slow">
          <h2 className="text-center">General Questions</h2>
          <Accordion fluid styled>
            <Accordion.Title active={activeIndex === 0} index={0} onClick={toggleAccordion}>
              <p>
                <Icon name="dropdown" />
                <strong>What kind of payment is accepted?</strong>
              </p>
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
              <p>
                We accept Cash, Checks (Personal, Business, Cashier), and Credit Cards (Visa,
                American Express, Discover, MasterCard).
              </p>
            </Accordion.Content>

            <Accordion.Title active={activeIndex === 1} index={1} onClick={toggleAccordion}>
              <p>
                <Icon name="dropdown" />
                <strong>What type of drain system do I need?</strong>
              </p>
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 1}>
              <p>
                Please refer to the Novo Pedi-Spa Massage Chair Specifications for drain system
                details. If you need assistance, please <Link to="/contact-us">contact us</Link>.
              </p>
            </Accordion.Content>

            <Accordion.Title active={activeIndex === 2} index={2} onClick={toggleAccordion}>
              <p>
                <Icon name="dropdown" />
                <strong>What type of electrical system do I need?</strong>
              </p>
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 2}>
              <p>
                Please refer to the Pedi-Spa Massage Chair Specifications for electrical system
                details. If you need assistance, please <Link to="/contact-us">contact us</Link>.
              </p>
            </Accordion.Content>
            <Accordion.Title active={activeIndex === 3} index={3} onClick={toggleAccordion}>
              <p>
                <Icon name="dropdown" />
                <strong>What is included with the purchase of the pedi-spa massage chair?</strong>
              </p>
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 3}>
              <p>
                For every pedi-spa massage chair that you purchase, you will also receive a Pedi
                Stool. With each order, you will also receive a Marketing Kit, that will assist in
                informing clients about your pedi-spa massage chair with a Disposable Pedicure Jet™
                system to help keep them safe. The Marketing Kit includes: a beautiful 24x36” Salon
                Poster, 8.5x11” laminated Handouts customized with your salon name and/or logo, and
                (for California residents) an 11x17” Poster on the official California Board of
                Barbering and Cosmetology Health and Safety Regulations.
              </p>
            </Accordion.Content>
            <Accordion.Title active={activeIndex === 4} index={4} onClick={toggleAccordion}>
              <p>
                <Icon name="dropdown" />
                <strong>Do you offer a warranty on the pedicure spa massage chair?</strong>
              </p>
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 4}>
              <p>
                We offer a 1-Year Warranty on the pedi-spa massage chair, and a 3-Year Warranty on
                the pedicure jet pump. Please <Link to="/contact-us">contact us</Link> for details.
              </p>
            </Accordion.Content>
          </Accordion>
        </div>
      </Layout>
    </>
  )
}

export default GeneralPage
